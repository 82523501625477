import Swiper from 'swiper'
import { Autoplay, Controller, Navigation, Pagination } from 'swiper/modules'

Swiper.use([Autoplay, Controller, Navigation, Pagination])


function init() {
  new Swiper('.cases-slider .swiper', {
    slidesPerView: 'auto',
    navigation: {
      prevEl: '.cases-slider .slider-prev',
      nextEl: '.cases-slider .slider-next',
    },
    pagination: {
      el: '.cases-slider .slider-pagination',
      clickable: true
    }
  })

  new Swiper('.reviews-slider .swiper', {
    slidesPerView: 'auto',
    navigation: {
      prevEl: '.reviews-slider .slider-prev',
      nextEl: '.reviews-slider .slider-next',
    },
    pagination: {
      el: '.reviews-slider .slider-pagination',
      clickable: true
    }
  })
}

init()
